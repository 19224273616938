import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { SharedService } from "../services/shared.service";

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean> | boolean => {

    const authService = inject(AuthService);
    const sharedService = inject(SharedService);

    if (authService.isAuthenticated()) {

        sharedService.getRedirectUrl().subscribe((redirectUrl) => {
            if (redirectUrl) {
                window.location.href = window.atob(redirectUrl);
            }
            else {
                window.location.href = environment.sandbox_console_host;
            }
        })
        return false;
    } else {
        return true;
    }
}



export const isNotLoggedInGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    canActivate(route, state)
