import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../bean/User';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    formData: BehaviorSubject<any | User>;
    constructor() {
        this.formData = new BehaviorSubject<any | User>(null);
    }

    getFormData() {
        return this.formData.asObservable();
    }

    setFormData(formData: User | any) {
        this.formData.next(formData);
    }

    clearDataStream() {
        this.formData.unsubscribe();
        this.formData = new BehaviorSubject<any>(null);
    }
}
