import { GoogleSigninButtonModule, SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Subject, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { AuthService } from '../../services/auth.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { OAuthService } from '../../services/oauth.service';
import { SharedService } from '../../services/shared.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, RouterModule, CommonModule, SocialLoginModule, GoogleSigninButtonModule, RiveModule, ReactiveFormsModule, RxReactiveFormsModule]
})
export class LoginComponent {
  loginForm: FormGroup;
  email: any;
  isUser: boolean = false;
  redirectUrl: string | undefined;
  saveForm: boolean = false;
  showPassword: boolean = false;
  googleLogin: boolean = false;
  destroy$: Subject<void> = new Subject<void>();
  incorrectPassword: boolean = false;
  googleError: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private snackBarService: SnackbarService,
    private authService: AuthService,
    private sharedService: SharedService,
    private oauthService: OAuthService,
    private fb: RxFormBuilder,
    private store: Store,
    private actions$: Actions
  ) {
    this.loginForm = this.fb.group({
      email: ['', [RxwebValidators.notEmpty({ message: 'Email is required' }), RxwebValidators.email({ message: 'Invalid email' })]],
      password: ['', [RxwebValidators.notEmpty({ message: 'Password is required' }), RxwebValidators.maxLength({ value: 256, message: "Invalid password" })]],
    });
    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!this.redirectUrl)
        this.redirectUrl = params['redirect'] ? btoa(params['redirect']) : undefined;
      this.email = params['email'] ? atob(params['email']) : undefined
      if (this.email) {
        this.loginForm.controls['email'].patchValue(this.email)
      }

    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const code = params['code'];
      if (code) {
        this.saveForm = true;
        this.oauthService.handleAuthCallback(code).subscribe({
          next: (response) => {
            this.authService.setSessionToken(response.data.access_token);
            this.authService.setRefereshToken(response.data.refresh_token);
            if (this.redirectUrl) {
              window.location.href = window.atob(this.redirectUrl);
            }
            else {
              window.location.href = environment.sandbox_console_host;
            }
            this.saveForm = false;
          },
          error: (error) => {
            this.saveForm = false;
            this.snackBarService.openSnackBar("Failed to Login with Google", undefined);
          }
        });
      }
    }
    )
  }


  isValid() {
    return this.loginForm.valid && this.loginForm.controls['email'].value
  }

  showError() {
    this.loginForm.markAllAsTouched();
  }
  verify() {

    this.saveForm = true;

    this.store.dispatch(UserActions.LOGIN({
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value
    }))

    this.actions$.pipe(ofType(UserActions.LOGIN_SUCCESS), take(1)).subscribe((data) => {
      this.authService.setSessionToken(data.accessToken);
      this.authService.setRefereshToken(data.refreshToken);
      if (this.redirectUrl) {
        window.location.href = atob(this.redirectUrl.trim());
      }
      else {
        window.location.href = environment.sandbox_console_host;
      }
      this.saveForm = false;

    })
    this.actions$.pipe(ofType(UserActions.LOGIN_FAILED), take(1)).subscribe(({ err }) => {
      if (err.error.message) {
        if (err.error.message.includes('Bad credentials')) {
          this.incorrectPassword = true;
        }
        else {
          this.snackBarService.openSnackBar(err.error.message, undefined);
        }
      };
      this.saveForm = false;

    })
  }

  navigateToForgotPassword() {
    this.router.navigate(['/set-password/verify'], {
      queryParams: {
        email: this.email
      },
    });
  }

  loginWithGoogle() {
    this.oauthService.authorizeGoogle();
  }


}

