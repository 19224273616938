<div class="flex flex-row justify-center">
  <div class="flex flex-col bg-none min-h-screen z-10 flex-grow w-1/2 relative">
    <div class="z-30 mt-[96px] ml-[40px] w-[489px] flex flex-col gap-2">
      <p class="text-headline-semibold">Get API Keys within minutes
        and start testing</p>
      <p class="text-body-2-regular">Integrate with rich, developer friendly APIs </p>
    </div>
    <img src="assets/images/console_ss.png" alt="" class=" absolute z-30 w-2/3 h-[50vh] mt-[332px]">
    <img src="assets/images/tilted_background.png" alt="Tilted Rectangle" class="z-20 absolute w-[1602px] h-[875.18px]">
  </div>
  <div class="flex flex-col justify-between bg-white z-50 px-[32px] py-[40px] w-1/2">
    <!-- sandbox logo & login cta -->
    <div class="flex flex-row justify-between pb-3">
      <mat-icon svgIcon="ic_dark_sandbox_word_logo" class="w-[102px] h-4"></mat-icon>
      <div>
        <span class="text-body-1-regular text-medium">Have an account? <font [routerLink]="['/login']"
            class="text-body-1-medium text-navy-blue-600 cursor-pointer">Log in</font></span>
      </div>
    </div>

    <!-- signup div -->
    <div class="flex flex-col gap-12">
      <div class="flex flex-col gap-[8px]">
        <span class="text-subheading-1-semibold text-dark">Start your free trial</span>
        <span class="text-body-1-medium text-medium">Power your onboarding and tax compliance flows with us</span>
      </div>
      <!-- signup form div -->
      <div class="flex flex-col">
        <form [formGroup]="accountDetailForm" (keyup.enter)="isValid()? onGetStartedClick():showError()"
          (ngSubmit)="isValid()? onGetStartedClick():showError()" class="flex flex-col gap-2">
          <!-- email form field -->
          <mat-form-field appearance="outline" class="w-[584px]">
            <mat-label>Email</mat-label>
            <input matInput required placeholder="Business Email" formControlName="email" type="email">
            <mat-error>
              <ng-container *ngIf="
                          accountDetailForm.controls['email'].errors &&
                          (accountDetailForm.controls['email'].dirty || accountDetailForm.controls['email'].touched)
                        ">
                <ng-container *ngFor="let error of accountDetailForm.controls['email'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <!-- firstName & lastName div -->
          <div class="flex flex-row gap-6 w-[584px]">
            <!-- firstName form field -->
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>First name</mat-label>
              <input matInput required placeholder="First name" formControlName="firstName" type="text">
              <mat-error>
                <ng-container *ngIf="
                            accountDetailForm.controls['firstName'].errors &&
                            (accountDetailForm.controls['firstName'].dirty || accountDetailForm.controls['firstName'].touched)
                          ">
                  <ng-container *ngFor="let error of accountDetailForm.controls['firstName'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                      <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
            <!-- lastName form field -->
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Last name</mat-label>
              <input matInput required placeholder="Last name" formControlName="lastName" type="text">
              <mat-error>
                <ng-container *ngIf="
                            accountDetailForm.controls['lastName'].errors &&
                            (accountDetailForm.controls['lastName'].dirty || accountDetailForm.controls['lastName'].touched)
                          ">
                  <ng-container *ngFor="let error of accountDetailForm.controls['lastName'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                      <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>

          <!-- business form field -->
          <mat-form-field appearance="outline" class="w-[584px]">
            <mat-label>Company</mat-label>
            <input matInput required placeholder="Company" formControlName="business" type="text">
            <mat-error>
              <ng-container *ngIf="
                          accountDetailForm.controls['business'].errors &&
                          (accountDetailForm.controls['business'].dirty || accountDetailForm.controls['business'].touched)
                        ">
                <ng-container *ngFor="let error of accountDetailForm.controls['business'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <!-- phone form field -->
          <div class="flex flex-row w-[584px] gap-[8px]">
            <mat-form-field appearance="outline" class="w-[94px]">
              <mat-select class="flex w-[94px]" [(value)]="selectedCountry" panelWidth="400px">
                <mat-select-trigger>
                  <img *ngIf="selectedCountry" matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-[8px]" />
                </mat-select-trigger>
                <mat-option *ngFor=" let c of countryList" [value]="c">
                  <div class=" flex">
                    <img src="{{c.flag}}" alt="flag" class="h-5 w-7 mr-3" />
                    <span>{{c.name}}</span>
                  </div>
                </mat-option>
              </mat-select>
              <mat-icon class="text-dark py-2 material-symbols-rounded" matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-[482px]" floatLabel="always">
              <mat-label>Phone</mat-label>
              <p matTextPrefix class="text-body-1-regular text-medium mr-[12px]" *ngIf="selectedCountry">
                +{{selectedCountry.numeric_code}}</p>
              <input matInput formControlName="phone" type="text">
              <mat-error>
                <ng-container *ngIf="
                            accountDetailForm.controls['phone'].errors &&
                            (accountDetailForm.controls['phone'].dirty || accountDetailForm.controls['phone'].touched)
                          ">
                  <ng-container *ngFor="let error of accountDetailForm.controls['phone'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                      <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-error>
            </mat-form-field>
          </div>
        </form>

        <!-- privacy policy & terms of use -->
        <div>
          <div class="flex flex-row mt-[24px]">
            <span class="text-note-regular text-medium">By clicking "Get Started", you agree to our <a
                class="text-navy-blue-600" href="#" target="_blank">Terms of Use</a> and <a class="text-navy-blue-500"
                href="#" target="_blank">Privacy Policy</a></span>
          </div>

          <!-- Get Started cta -->
          <div class="flex flex-wrap justify-start mt-4">
            <ng-container *ngIf="!saveForm; else loading" matRipple>
              <button
                class="text-body-1-regular hover:bg-navy-blue-800/[0.98] text-white rounded-md min-w-[362px] bg-navy-blue-800 py-3 px-8 disabled:bg-slate-400"
                (click)="isValid()? onGetStartedClick():showError()" matRipple>Get Started</button>
            </ng-container>
          </div>
          <ng-template #loading>
            <button
              class="text-base font-medium text-white rounded-md min-w-[362px] bg-navy-blue-800 h-12 flex justify-center">
              <canvas riv="cta-loading" width="90" height="45">
                <riv-player name="loading-1" play></riv-player>
              </canvas>
            </button>
          </ng-template>

          <!-- divider -->
          <div class="flex flex-row items-center justify-center mt-4 w-[362px]">
            <div class="flex-1">
              <mat-divider></mat-divider>
            </div>
            <div class="px-3 mat-typography text-note-regular text-medium">or</div>
            <div class="flex-1">
              <mat-divider></mat-divider>
            </div>
          </div>

          <!-- social logins -->
          <div class="flex flex-row justify-between w-[362px]">

            <button (click)="loginWithGoogle()"
              class=" hover:bg-grey-bg-400 border h-12 py-3 mt-4 rounded-md w-full text-navy-blue-800">
              <div class="flex flex-row gap-2 items-center justify-center">
                <img src="assets/icons/google_logo.svg">
                <span class="text-body-1-medium">
                  Continue with Google
                </span>
              </div>
            </button>

          </div>
        </div>
      </div>
    </div>

    <!-- sandbox copyright -->
    <div class="flex">
      <span class="text-caption-regular text-light">© 2023 Sandbox</span>
    </div>

  </div>
</div>