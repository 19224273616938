import { createAction, props } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { User } from '../../bean/User';

export const UserActions = {
    LOGIN: createAction('[USER] LOGIN ' + uuidv4(), props<{ email: string; password: string }>()),
    LOGIN_SUCCESS: createAction('[USER] LOGIN Success ' + uuidv4(), props<{ accessToken: string, refreshToken: string }>()),
    LOGIN_FAILED: createAction('[USER] LOGIN Failed ' + uuidv4(), props<{ err: any }>()),

    SIGNUP: createAction('[USER] SIGNUP ' + uuidv4(), props<{ user: User; redirectUrl: string }>()),
    SIGNUP_SUCCESS: createAction('[USER] SIGNUP Success ' + uuidv4(), props<{ user: User }>()),
    SIGNUP_FAILED: createAction('[USER] SIGNUP Failed ' + uuidv4(), props<{ err: any }>()),

    CHECK_USER: createAction('[USER] CHECK User ' + uuidv4(), props<{ username: string }>()),
    CHECK_USER_SUCCESS: createAction('[USER] CHECK User Success ' + uuidv4(), props<{ exists: boolean }>()),
    CHECK_USER_FAILED: createAction('[USER] CHECK User Failed ' + uuidv4(), props<{ err: string }>()),

    CHANGE_PASSWORD: createAction('[USER] CHANGE Password ' + uuidv4(), props<{ email: string; tempPassword: string; newPassword: string }>()),
    CHANGE_PASSWORD_SUCCESS: createAction('[USER] CHANGE Password Success ' + uuidv4()),
    CHANGE_PASSWORD_FAILED: createAction('[USER] CHANGE Password Failed ' + uuidv4(), props<{ err: any }>()),

    // UPDATE_USER: createAction('[USER] UPDATE User ' + uuidv4(), props<{ user: User; token: string }>()),
    // UPDATE_USER_SUCCESS: createAction('[USER] UPDATE User Success ' + uuidv4(), props<{ user: User }>()),
    // UPDATE_USER_FAILED: createAction('[USER] UPDATE User Failed ' + uuidv4(), props<{ err: string }>()),

    VERIFY_SIGNUP: createAction('[USER] VERIFY Signup ' + uuidv4(), props<{ email: string; confirmationCode: string }>()),
    VERIFY_SIGNUP_SUCCESS: createAction('[USER] VERIFY Signup Success ' + uuidv4()),
    VERIFY_SIGNUP_FAILED: createAction('[USER] VERIFY Signup Failed ' + uuidv4(), props<{ err: any }>()),
};
