import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PasswordComponent } from './components/password/password.component';
import { SignupComponent } from './components/signup/signup.component';
import { VerificationComponent } from './components/verification/verification.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { isNotLoggedInGuard } from './route-guards/not-logged-in.guard';
import { signUpGuard } from './route-guards/sign-up.guard';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: "full" },
    {
        path: 'login', canActivate: [isNotLoggedInGuard],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
        ],
    },
    { path: 'signup', canActivate: [isNotLoggedInGuard], component: SignupComponent },
    { path: 'verify', component: VerifyEmailComponent },

    {
        path: 'signup/password',
        component: PasswordComponent,
        canActivate: [signUpGuard]
    },
    {
        path: 'signup/verification',
        component: VerificationComponent,
        canActivate: [signUpGuard]
    },
    { path: '**', redirectTo: 'login' }
];
