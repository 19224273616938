import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { ICON_REGISTRY_PROVIDER } from '@angular/material/icon';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { RIVE_FOLDER } from 'ng-rive';
import { routes } from './app.routes';
import { CustomSerializer } from './serializers/custom-route.serializer';
import { RootReducers } from './store/app.model';
import { ReferenceDataEffects } from './store/reference-data/reference-data.effects';
import { AuthEffects } from './store/user/user.effects';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideStoreDevtools(),
    provideStore(RootReducers),
    provideEffects(AuthEffects, ReferenceDataEffects),
    provideRouterStore({
      serializer: CustomSerializer
    }),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    ICON_REGISTRY_PROVIDER,
    {
      provide: RIVE_FOLDER,
      useValue: "/assets/animations"
    },
    provideAnimationsAsync(),
  ]
};
