import { RouterState } from "@angular/router"
import { routerReducer } from "@ngrx/router-store"
import { ReferenceDataReducer } from "./reference-data/reference-data.reducers"


export interface AppModel {
    [RootKeys.router]: RouterState
    [RootKeys.reference_data]: Record<string, any>
}

export enum RootKeys {
    router = 'router',
    reference_data = 'reference_data',
}

export const RootReducers = {
    [RootKeys.router]: routerReducer,
    [RootKeys.reference_data]: ReferenceDataReducer,
}