import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { instanceToPlain } from "class-transformer";
import { catchError, map, switchMap } from "rxjs";
import { EntitlementsService } from "../../services/entitlements.service";
import { SnackbarService } from "../../services/snackbar.service";
import { UserActions } from "./user.actions";



@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private entitlementService: EntitlementsService,
        private store: Store,
        private snackBarService: SnackbarService
    ) { }

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.LOGIN),
            switchMap(action =>
                this.entitlementService.login(action.email, action.password).pipe(
                    map(response => UserActions.LOGIN_SUCCESS({ accessToken: response.data.access_token, refreshToken: response.data.refresh_token })),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Login failed', undefined);
                        return UserActions.LOGIN_FAILED({ err: err });
                    })
                )
            )
        )
    );

    signup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.SIGNUP),
            switchMap(action =>
                this.entitlementService.signup(instanceToPlain(action.user), action.redirectUrl).pipe(
                    map(response => UserActions.SIGNUP_SUCCESS({ user: response.data! })),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Signup failed', undefined);
                        return UserActions.SIGNUP_FAILED({ err: err });
                    })
                )
            )
        )
    );

    checkUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.CHECK_USER),
            switchMap(action =>
                this.entitlementService.checkUser(action.username).pipe(
                    map(response => UserActions.CHECK_USER_SUCCESS({ exists: response.data })),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Failed to check user', undefined);
                        return UserActions.CHECK_USER_FAILED({ err: err });
                    })
                )
            )
        )
    );

    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.CHANGE_PASSWORD),
            switchMap(action =>
                this.entitlementService.changePassword(action.email, action.tempPassword, action.newPassword).pipe(
                    map(() => UserActions.CHANGE_PASSWORD_SUCCESS()),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Failed to change password', undefined);
                        return UserActions.CHANGE_PASSWORD_FAILED({ err: err });
                    })
                )
            )
        )
    );

    // updateUser$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(UserActions.UPDATE_USER),
    //         switchMap(action =>
    //             this.entitlementService.updateUser(action.user, action.token).pipe(
    //                 map(() => {
    //                     this.store.dispatch(UserActions.UPDATE_USER({ user: action.user, token: action.token }));
    //                     return UserActions.UPDATE_USER_SUCCESS({ user: action.user });
    //                 }),
    //                 catchError(async err => {
    //                     this.snackBarService.openSnackBar('Failed to update user', undefined);
    //                     return UserActions.UPDATE_USER_FAILED({ err: err });
    //                 })
    //             )
    //         )
    //     )
    // );

    verifySignup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.VERIFY_SIGNUP),
            switchMap(action =>
                this.entitlementService.verifySignup(action.email, action.confirmationCode).pipe(
                    map(() => UserActions.VERIFY_SIGNUP_SUCCESS()),
                    catchError(async err => {
                        this.snackBarService.openSnackBar('Failed to verify signup', undefined);
                        return UserActions.VERIFY_SIGNUP_FAILED({ err: err });
                    })
                )
            )
        )
    );
}
