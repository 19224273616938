import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { SharedService } from './services/shared.service';
import { ReferenceDataActions } from './store/reference-data/reference-data.actions';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'in-co-sandbox-accounts';
  constructor(private sharedService: SharedService, private activatedRoute: ActivatedRoute, private store: Store) {

    this.activatedRoute.queryParams.subscribe((params) => {


      if (params['url']) {
        this.sharedService.setRedirectUrl(params['url']);
      }
      if (params['invite']) {
        const redirectUrl = atob((params['invite'].split('=')[0])).split('redirect=')[1]
        this.sharedService.setRedirectUrl(btoa(redirectUrl));
      }
    });
  }


  ngOnInit() {
    this.store.dispatch(ReferenceDataActions.GET_COUNTRIES());
  }
}
