<div class="flex bg-dr_background bg-contain bg-no-repeat min-h-screen justify-center">
  <div class="flex flex-col mt-[90px] items-center justify-between">
    <!-- login div -->
    <div class="flex-row border-solid rounded-[5px] border-[1px] border-border-grey">
      <div class="flex flex-col w-[360px] justify-center rounded py-8 px-4 bg-white">
        <!-- welcome div -->
        <div class="flex flex-col items-center">
          <div>
            <mat-icon svgIcon="ic_sandbox_logo_circle" class="h-[50px] w-[50px]"></mat-icon>
          </div>
          <p class="text-subheading-1-medium text-navy-blue-800">Log in to Sandbox</p>
          <p class="mt-[8px] text-body-1-regular text-medium">Welcome back!</p>
        </div>

        <!-- login form div -->
        <form [formGroup]="loginForm" (keyup.enter)="isValid()?verify():showError()" class="mt-8">
          <!-- email form field -->
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email" type="email">
            <mat-error>
              <ng-container *ngIf="
                          loginForm.controls['email'].errors &&
                          (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)
                        ">
                <ng-container *ngFor="let error of loginForm.controls['email'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
          </mat-form-field>
          <!-- password form field -->
          <mat-form-field appearance="outline" class="w-full mt-2">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" [type]="showPassword ? 'text' : 'password'" autocomplete="off">
            <mat-error>
              <ng-container *ngIf="
                            loginForm.controls['password'].errors &&
                            (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)
                          ">
                <ng-container *ngFor="let error of loginForm.controls['password'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                    <span class="text-red-500 text-xs m-0 p-0">{{ error.value['message'] }}</span>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-error>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=false"
              *ngIf="showPassword">
              visibility</mat-icon>
            <mat-icon matSuffix class="material-symbols-rounded text-light" (click)="showPassword=true"
              *ngIf="!showPassword">
              visibility_off</mat-icon>
          </mat-form-field>
        </form>

        <!-- forgot pasword -->
        <div class="items-start">
          <!-- <button (click)="navigateToForgotPassword()" class="text-note-regular text-navy-blue-800">Forgot
            Password?</button> -->
        </div>

        <!-- login cta -->
        <div class="justify-center mt-8">
          <ng-container *ngIf="!saveForm; else loading">
            <button class="text-body-1-regular text-white rounded-[5px] bg-navy-blue-800 w-full py-3 px-8"
              (click)="isValid()?verify():showError()">Log in</button>
          </ng-container>
        </div>
        <ng-template #loading>
          <button class="text-base font-medium text-white rounded bg-navy-blue-800 w-full flex justify-center">
            <canvas riv="cta-loading" width="48" height="48">
              <riv-player name="loading-1" play></riv-player>
            </canvas>
          </button>
        </ng-template>

        <!-- divider -->
        <div class="flex flex-row items-center justify-center mt-4">
          <div class="flex-1">
            <mat-divider></mat-divider>
          </div>
          <div class="px-3 mat-typography text-note-regular text-medium">or Log in using</div>
          <div class="flex-1">
            <mat-divider></mat-divider>
          </div>
        </div>


        <button (click)="loginWithGoogle()"
          class="hover:bg-grey-bg-400 border h-12 py-3 mt-4 rounded-md w-full text-navy-blue-800">
          <div class="flex flex-row gap-2 items-center justify-center">
            <img src="assets/icons/google_logo.svg">
            <span class="text-body-1-medium">
              Continue with Google
            </span>
          </div>
        </button>

        <!-- sign up cta -->
        <div class="flex flex-row mt-8 text-body-1-regular justify-center">
          <span class="text-medium">New to Sandbox?</span>&nbsp;<span [routerLink]="['/signup']"
            class="text-navy-blue-800 cursor-pointer">Get
            Started</span>
        </div>

      </div>
    </div>

    <!-- sandbox copyright -->
    <div class="mb-[32px]">
      <span class="text-caption-regular text-light">© 2023 Sandbox</span>
    </div>
  </div>
</div>