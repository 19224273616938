import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { EntitlementsService } from '../../services/entitlements.service';
import { SnackbarService } from '../../services/snackbar.service';
import { UserActions } from '../../store/user/user.actions';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
  standalone: true,
  imports: [AngularMaterialModule]
})
export class VerifyEmailComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private snackBarService: SnackbarService,
    private store: Store,
    private actions$: Actions
  ) { }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      // This occurs when user comes from invited route.

      const email = decodeURIComponent(params['email'])
      const code = params['code']
      const redirect = params['redirect']

      if (email && code) {
        // this.entitlementsService.verifySignup(email, code).subscribe({
        //   next: (response: any) => {
        //     this.snackBarService.openSnackBar('Email successfully verified', undefined);

        //     if (!redirect || redirect == "undefined") {
        //       this.router.navigate(['/login']);
        //     }
        //     else {
        //       this.router.navigate(['/login'], {
        //         queryParams: {
        //           redirect: redirect
        //         }
        //       })
        //     }
        //   },
        //   error: (err) => {
        //     this.snackBarService.openSnackBar('Email verification failed', undefined);
        //     this.router.navigate(['signup'], {
        //       queryParams: {
        //         email: email
        //       }
        //     });
        //   }
        // });

        this.store.dispatch(UserActions.VERIFY_SIGNUP({ email: email, confirmationCode: code }));

        this.actions$.pipe(ofType(UserActions.VERIFY_SIGNUP_SUCCESS), take(1)).subscribe((data) => {
          this.snackBarService.openSnackBar('Email successfully verified', undefined);
          if (!redirect || redirect == "undefined") {
            this.router.navigate(['/login'],
              {
                queryParams: {
                  email: btoa(email)
                }
              }
            );
          }
          else {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect: redirect,
                email: btoa(email)
              }
            })
          }

        })
        this.actions$.pipe(ofType(UserActions.VERIFY_SIGNUP_FAILED), take(1)).subscribe((data) => {
          this.snackBarService.openSnackBar('Email verification failed', undefined);
          this.router.navigate(['signup'], {
            queryParams: {
              email: email
            }
          });
        })
      }

    })

  }
}
