import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { CacheService } from "../services/cache.service";

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): Observable<boolean> | boolean | Promise<boolean> => {

    const router = inject(Router)
    const cacheService = inject(CacheService);
    return cacheService.getFormData().pipe(
        map(user => {
            if (user?.email && user.business && user.first_name && user.last_name) {
                return true;
            }
            else {
                const emailQueryParam = route.queryParams['email'];
                if (emailQueryParam) {
                    return true;
                } else {
                    router.navigate(['/signup']);
                    return false;
                }
            }
        })
    );
}


export const signUpGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    canActivate(route, state)
