import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxFormBuilder, RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RiveModule } from 'ng-rive';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { User } from '../../bean/User';
import { AngularMaterialModule } from '../../modules/angular-material/angular-material.module';
import { CacheService } from '../../services/cache.service';
import { EntitlementsService } from '../../services/entitlements.service';
import { OAuthService } from '../../services/oauth.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { SnackbarService } from '../../services/snackbar.service';
import { CountrySelectors } from '../../store/reference-data/reference-data.selectors';
import { UserActions } from '../../store/user/user.actions';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  standalone: true,
  imports: [AngularMaterialModule, RiveModule, CommonModule, RouterModule, ReactiveFormsModule, RxReactiveFormsModule]
})
export class SignupComponent implements OnInit {
  accountDetailForm: FormGroup;
  userCountryCode = 91;
  countryList: any;
  selectedCountry: any;
  saveForm: boolean = false;
  passwordView: boolean = false;
  verificationEmailView: boolean = false;
  showPassword: boolean = false;
  userObject: User | undefined;
  countries$: Observable<Record<string, any>> = this.store.select(CountrySelectors.COUNTRIES)
  destroy$: Subject<boolean> = new Subject<boolean>();
  redirectUrl: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private entitlementsService: EntitlementsService,
    private snackBarService: SnackbarService,
    private router: Router,
    private oauthService: OAuthService,
    private store: Store,
    private actions$: Actions,
    private referenceDataService: ReferenceDataService,
    private fb: RxFormBuilder
  ) {

    this.accountDetailForm = this.fb.formGroup(new User());

    this.cacheService.getFormData().pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      this.accountDetailForm.patchValue(user)

      if (user && user.countryCode)
        this.userCountryCode = parseInt(user.countryCode)
    })


    this.countries$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.countryList = data
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numeric_code === this.userCountryCode
        );
        if (!this.selectedCountry) {
          this.selectedCountry = this.countryList.find(
            (c: any) => c.numeric_code === 91
          );
        }
      }
    });

  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params: any) => {

      const inviteData = params['invite'];
      if (inviteData) {
        const decodedData = (atob(inviteData.split('=')[0])).split('&redirect=');
        this.redirectUrl = decodedData[1]
        const email = (((decodedData[0].split('email=')[1] as string)))
        this.accountDetailForm.controls['email'].setValue(email);
      }
    });

    this.accountDetailForm.controls['phone'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value && value.length <= 15 && value.length > 0) {
          // Format phone number with space
          this.accountDetailForm.controls['phone'].setValue(this.formatPhoneNumber(value), { emitEvent: false });
        }
      });
    this.actions$.pipe(ofType(UserActions.CHANGE_PASSWORD_FAILED), take(1)).subscribe(({ err }) => {
      if (err.error.message) {
        this.snackBarService.openSnackBar(err.error.message, undefined);
        this.saveForm = false;
      }
    })
  }

  isValid() {
    return this.accountDetailForm.valid && this.userCountryCode
  }
  showError() {
    this.accountDetailForm.markAllAsTouched();
  }

  onGetStartedClick() {
    {

      this.userObject = this.accountDetailForm.getRawValue();
      this.userObject!.countryCode = String(this.userCountryCode);
      this.cacheService.setFormData({
        ...this.userObject
      })
      this.saveForm = true;

      this.store.dispatch(UserActions.CHECK_USER({ username: this.accountDetailForm.controls['email'].value }))

      this.actions$.pipe(ofType(UserActions.CHECK_USER_SUCCESS), take(1)).subscribe((data) => {
        if (data.exists) {
          this.snackBarService.openSnackBar("Account already registered with this email", undefined);
          this.saveForm = false;
        }
        else {
          let encodedData;
          if (this.redirectUrl) {
            encodedData = btoa(`email=${encodeURIComponent(this.accountDetailForm.controls['email'].value)}&redirect=${this.redirectUrl}`)
            this.router.navigate(['signup', 'password'], {
              queryParams: {
                "invite": encodedData
              }
            })
          }
          else {
            this.router.navigate(['signup', 'password'], {
              queryParams: {
                "email": this.accountDetailForm.controls['email'].value
              }
            })
          }
        }
      })

    }
  }

  formatPhoneNumber(phoneNumber: string) {
    // Remove all non-numeric characters
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
    // Add spacing every five digits
    const formattedPhoneNumber = numericPhoneNumber.replace(/(\d{5})/g, '$1 ').trim();

    return formattedPhoneNumber;
  }

  loginWithGoogle() {
    this.saveForm = true;
    this.oauthService.authorizeGoogle();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
