import { createReducer, on } from "@ngrx/store";
import { ReferenceDataActions } from "./reference-data.actions";

const initialState = undefined

const onGetCountriesSuccess = on(ReferenceDataActions.GET_COUNTRIES_SUCCESS, (state: any, { countries }) => {
    return { countries: countries }
});


export const ReferenceDataReducer = createReducer(
    initialState,
    onGetCountriesSuccess
);